/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';

function MembershipFAQ({ noHeader, lang }) {
  const [questions, setQuestions] = useState([]);
  const toggleAnswer = (id, e) => {
    e.preventDefault();
    const cloneQuestions = [...questions];
    cloneQuestions.forEach((faq) => {
      if (faq.id === id) {
        faq.isOpen = !faq.isOpen;
      }
    });
    setQuestions(cloneQuestions);
  };

  const faq = {
    en: [
      {
        id: 1,
        title: 'How do I join?',
        answer: 'Your email will contain a login link. Click through to access your membership.',
      },
      {
        id: 2,
        title: 'Where can I track progress?',
        answer: 'You can track your progress by logging into your Miracle Moments account and going to your benefits page. When you level up to a new tier, you will see all the new perks on your benefits page!',
      },
      {
        id: 3,
        title: 'How is my membership status determined?',
        answer: 'Your membership status is determine by your spend.',
      },
      {
        id: 4,
        title: 'How do I earn?',
        answer: 'Earn by purchases, having a birthday, referring friends, and more!',
      },
      {
        id: 5,
        title: 'Do tiers/points expire or reset?',
        answer: 'If there is 12 months of inactivity, your points will expire. Your tier is reset every year.',
      },
    ],
  };

  useEffect(() => {
    setQuestions(faq.en);
  }, []);

  return (
    <div className="asset-faq">
      {!noHeader
      && <h2 className="digital-asset__subheader -center">FAQ</h2>}
      {questions.map((faq) => (
        <div
          key={faq.id}
          className="asset-faq__item">
          {faq.isOpen
            ? (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                -
              </a>
            )
            : (
              <a
                className="asset-faq__toggle"
                href="#"
                onClick={(e) => toggleAnswer(faq.id, e)}>
                +
              </a>
            )}
          <div
            className="asset-faq__item--question"
            onClick={(e) => toggleAnswer(faq.id, e)}>
            {faq.title}
          </div>
          <div
            className={faq.isOpen ? 'asset-faq__item--answer -open' : 'asset-faq__item--answer'}
            dangerouslySetInnerHTML={{ __html: faq.answer }}>
          </div>
        </div>
      ))}
      
    </div>
  );
}

export default MembershipFAQ;
